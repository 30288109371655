import { Component } from 'react';
class Translate_pl_PL extends Component {
    static Trans = {
        Error:{
            Error:{
                IsRequire:"Pole $label$ jest wymagane!",
                HasMinLength:"Wartość musi być dłuższa niż $minLength$ znaków",
                HasMaxLength:"Wartość musi być krótsza niż $maxLength$ znaków"
            }
        },
        Edupanel: {
            alts: {
                "/imgs/logo.png": "Logo Edupanel.pl",
                "/imgs/flags/EN.svg": "Tłumacz na Angielski",
                "/imgs/flags/PL.svg": "Tłumacz na Polski",
                "/imgs/www/polPriv.png": "Rysunek przedstawiający kota",
                "/imgs/www/home.png": "Rysunek przedstawiający kota",
                "/imgs/www/goals.png": "Rysunek przedstawiający kota",
                "/imgs/www/mission.png": "Rysunek przedstawiający kota",
                "/imgs/www/dotation.png": "Rysunek przedstawiający kota",
                "/imgs/www/apps.png": "Rysunek przedstawiający kota"
            },
            "no_page":{
                "title":"Brak Strony"
            }
        },
            SSO:{
                SSOLoginForm:{
                    titleForm:"Logowanie do Systemu",
                    login:{
                        label:"Login",
                        placeholder:"Login"
                    },
                    password:{
                        label:"Hasło",
                        placeholder:"Hasło"
                    },
                    loginToSystem:{
                        label:"Zaloguj się"
                    },
                    register:{
                        label:"Zarejestruj się"
                    },
                }
            },
            EduShopApp: {
                EduShopAppHomePage: {
                    new: "Nowa lista",
                    shopping: "Zakupy",
                    edit: "Edycja",
                    about_us: "O nas"
                },
                EduShopNewListPage:{
                    list:"Lista zakupowa"
                },
                NoSoundPage:{
                    no_sound:"Naciśnij przycisk aby przejść dalej"
                },EduShopAppAboutUsPage: {
                    title: "O nas",
text: "Tworzymy wysokiej jakości aplikacje i gry edukacyjne dla osób niepełnosprawnych, zwłaszcza dla niewidomych. Naszym celem jest ułatwienie życia i poszerzenie wiedzy naszych użytkowników. Rozumiemy, że rozwój oprogramowania wymaga zasobów finansowych, dlatego potrzebujemy wsparcia, aby kontynuować tworzenie innowacyjnych rozwiązań. Jesteśmy wdzięczni za każdą pomoc i wsparcie finansowe, które otrzymujemy. Darczyńcy, którzy rozumieją znaczenie naszej misji, przyczyniają się do rozwoju naszych projektów i umożliwiają nam maksymalny wpływ na społeczność osób niepełnosprawnych. Poprzez wsparcie organizacji Edupanel.pl, darczyńcy stają się częścią naszej społeczności, która dąży do eliminowania barier i tworzenia bardziej włączającego świata dla osób niepełnosprawnych. Doceniamy każdą formę wsparcia, ponieważ pozwala nam kontynuować naszą misję i dostarczać wartościową edukację osobom niewidomym na całym świecie."
                },
                GameFinish:{
                    new_game:"Nowa Gra",
                    back:"Wstecz"
                },
                EduShopAppPlayerWinPage:{
                    title:"Gratlacje. Wygrałeś."
                },
                EduShopAppPlayerLostPage:{
                    title:"Niesty przegrałeś"
                },
                EduShopAppPlayerDrawPage:{
                    title:"Jest remis."
                },
                EduShopAppHowToPlayPage: {
                    title: "Zasady gry",
text: "<ol><li>Gra rozpoczyna się na planszy o wymiarach 3x3.</li><li>Na początku gry plansza jest pusta.</li> <li>Gracze wykonują ruchy na przemian - najpierw gracz, a następnie przeciwnik AI.</li> <li>Gracz wybiera swoje pole poprzez wypowiedzenie numeru odpowiedniego pola (na przykład \"Wybierz pole numer 1\",\"Wybierz pole numer 2\" i tak dalej) lub kliknięcie na wybrane pole na ekranie.</li> <li>Po wybraniu pola, gracz stawia swój znak na planszy. Pole zostaje oznaczone jako zajęte.</li> <li>Jeśli gracz lub AI ułoży trzy swoje znaki w jednej linii - poziomej, pionowej lub ukośnej, gra kończy się, a zwycięzca zostaje  ogłoszony.</li> <li>Jeśli na planszy nie ma już dostępnych pól i nikt nie ułożył trzech znaków w jednej linii, gra kończy się remisem.</li> <li>Po zakończeniu gry, gracz może zdecydować, czy chce rozpocząć kolejną partię.</li></ol>"
                }
                
            }
        };
}
export default Translate_pl_PL;