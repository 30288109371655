class ProductSearch {
    constructor(products) {
      this.products = products;
    }
  
    searchProduct(query) {
      const res = { name: "", prob: 0 };
      const queryLower = query.toLowerCase();
  
      // Calculate distances
      const distances = this.products.map(product => {
        const nameLower = product.name.toLowerCase();
        return this.editDistance(queryLower, nameLower);
      });
  
      // Calculate probabilities
      const probabilities = distances.map(distance => 1 / (1 + Math.exp(-distance)));
  
      // Find the best match
      const bestIndex = distances.indexOf(Math.min(...distances));
      
      if (bestIndex !== -1) {
        let prob = probabilities[bestIndex];
        if (prob>=0.8){
          return res;
        }
        res.name = this.products[bestIndex].name;
        res.prob = probabilities[bestIndex];
      }
      return res;
    }
  
    editDistance(s1, s2) {
      const m = s1.length;
      const n = s2.length;
      const dp = Array(m + 1).fill(null).map(() => new Array(n + 1).fill(null));
  
      for (let i = 0; i <= m; i++) {
        dp[i][0] = i;
      }
      for (let j = 0; j <= n; j++) {
        dp[0][j] = j;
      }
  
      for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
          dp[i][j] = s1[i - 1] === s2[j - 1]
            ? dp[i - 1][j - 1]
            : Math.min(
                dp[i - 1][j],
                dp[i][j - 1],
                dp[i - 1][j - 1]
              ) + 1;
        }
      }
      return dp[m][n];
    }
  }
  export default ProductSearch;