
import DbModel from './DbModel';

class ESAList extends DbModel{
    constructor(){
        super();
        this.tableName="esalist";
    }
    
    async createTable(){
        let props={
            id: {
                type: 'string',
                maxLength: 40
            },
            name: {
                type: 'string'
                ,maxLength:50
                ,minLength:3
            },
            userId: {
                type: 'string'
                ,maxLength:40
                ,minLength:3
            },
            timestamp: {
                type: 'string',
                format: 'date-time'
            }
        };
        await this.db.createTable(this.tableName,"id",props,["id","name","userId"]);
    }
}
export default ESAList;