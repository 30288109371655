import EduDb from './EduDb';
import {Tools} from 'educoreapp2';
import ESAUser from "./ESAUser";
import ESAListItem from './ESAListItem';
import ESASession from './ESASession';
import ESAList from './ESAList';
import ESAProduct from './ESAProduct';
import ESAProductCategory from './ESAProductCategory';
class DBMethods{

     async getAllProducts(devid,sessid){
          let esaProduct=new ESAProduct();
       
          let list=await esaProduct.select();
          console.log("list ",list);
         return list;
     }
     async getCategory(devid,sessid){
          let esaCategory=new ESAProductCategory();
       
          let list=await esaCategory.select();
          console.log("list ",list);
         return list;
     }

     async getList(devid,sessid,listId){
          let res={};
          let esaList=new ESAList();
          let MAP={
               "id":"id",
               "name":"prod_name",
               "category":"cat_name",
               "catid":"cat_id"
          };

          let list=await esaList.select(
               {tab:[{"name":"id","type":"eq","val":listId}]}
          );
          list=list[0];
          let ll=new ESAListItem();
          let rr=await ll.select(
               {tab:[{"name":"listId","type":"eq","val":listId}]
               ,esaproduct:[]
               ,esaproductcategory:[]
          },[
     {"tableName":"esaproduct","whereName":"esaproduct","res":"prod","left":"productId","right":"id"}
     ,{"tableName":"esaproductcategory","whereName":"esaproductcategory","res":"cat","left":"prod_categoryId","right":"id"}
],MAP
          );
          res={...list,"list":rr};
        //  console.log("list ",res);
         return res;
     }

     async getSession(devid,userid){
          let ss=new ESASession();
          let xx=await ss.insert({"devid":devid,"userid":userid});
          return xx.id;
     }
     async autoLogin(devid){
          let esaUser=new ESAUser();
          let xx=await esaUser.select({tab:[
               {"name":"devid","type":"eq","val":devid}
          ]});
          if (Tools.empty(xx)){
               return null;
          }
         let id=xx[0].id;
          let sesid=await this.getSession(devid,id);
          let profile=await this.getProfile(devid,sesid);
          await this.setAppProfile(profile);
         return null;
     }
     async getProfile(devid,sesid){
          let ss=new ESAUser();
          let xx=await ss.select({
               tab:[{"name":"devid","type":"eq","val":devid}]
              ,esasession:[{"name":"id","type":"eq","val":sesid}]
     },[{"tableName":"esasession","whereName":"esasession","res":"ses","left":"id","right":"userid"}]);
     return xx;
         // console.log("xxxx",devid,id);
     }
     async setAppProfile(data){
          let dd=data[0];
          window.App.profile={
               "id":dd.id,
               "login":dd.login,
               "firstName":dd.firstName,
               "lastName":dd.lastName,
               "sessid":dd.ses_id
          }
     }
     async autoRegister(devid){
          let esaUser=new ESAUser();
          let xx=await esaUser.insert(
               {"id":devid,"devid":devid,"login":devid,"password":Tools.SHA1(devid)}
          );
         return xx;

     }
     async loginToSystem(devid,login,password){
          let esaUser=new ESAUser();
          let passwd=Tools.SHA1(password);
          let xx=await esaUser.select({
               tab:[{"name":"login","type":"eq","val":login}
               ,{"name":"password","type":"eq","val":passwd}]

     });
         return xx;
     }
     async exec(module, name, params, success, error) {
          let data=[];
          switch (name) {
               case "loginToSystem":data=this.loginToSystem(params["devid"],params["login"],params["password"]);
               break;
               case "getList":data=await this.getList(params["devid"],params["sessid"],params["listId"]);
               break;
               case "getAllProducts":data=await this.getAllProducts(params["devid"],params["sessid"]);
               break;
               case "getCategory":data=await this.getCategory(params["devid"],params["sessid"]);
               break;
          
               default:
                    break;
          }
          if (!Tools.empty(data)){
               success(data);
               return;
          }
     error(data);
     }
}
export default DBMethods;