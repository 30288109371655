import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {BaseAnimation,BaseLabel,PageWithMenu,BaseSlimPage,BaseImg,BaseCard,BaseButton,Translate,LogoEdupanel} from 'educoreapp2';
class EduShopAppLogoPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag="EduShopAppLogoPage";
    this.module="EduShopApp";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    
  }
  clickButton(pageName) {
    this.openPage(pageName);
  }
  openPage(pageName) {
    window.App.changePage("EduShopApp", pageName, null);
  }
  onEndAnimation(){
    window.App.changePage("EduShopApp","home", null);
  }
  render() {
    let TT = Translate.getT(window.App.lang, "EduShopApp", "LogoPage");
    let time=1000;
    let components = [
     { component: LogoEdupanel, time:1500,data:{
      backgroundColor:'white'
     } },
      /*{
        component: BaseLabel, time:2500, data: {
          text: 'Przedstawiamy',
          font: 'Arial',
          font_size:'40px',
          color: 'white'
        }
      },
      {
        component: BaseLabel, time: 75000, data: {
          text: 'EDUSHOPAPP',
          font: 'Arial',
          font_size:'40px',
          color: 'White'
        }
      }*/
    ];

    return (
        <div style={{backgroundColor:"white",display:"block"}} className='row h-100 g-0 no-scroll'>
          <div className='row h-20'></div>
          <div className='row h-50'>
            <div className='d-hide col-sm-2 col-md-3'></div>
            <BaseAnimation  className='col-xs-12 col-sm-8 col-md-6 g-10 h-100' components={components} onEnd={this.onEndAnimation}/>
          </div>
        </div>
        
    

    );
  }
}

export default EduShopAppLogoPage;