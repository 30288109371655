
import DbModel from './DbModel';

class ESAListItem extends DbModel{
    constructor(){
        super();
        this.tableName="esalistitem";
    }
    
    async createTable(){
        let props={
            id: {
                type: 'string',
                maxLength: 40
            },
            listId: {
                type: 'string'
                ,maxLength:40
                ,minLength:3
            },
            productId: {
                type: 'string'
                ,maxLength:40
                ,minLength:3
            },
            timestamp: {
                type: 'string',
                format: 'date-time'
            }
        };
        await this.db.createTable(this.tableName,"id",props,["id","listId","productId"]);
    }
}
export default ESAListItem;