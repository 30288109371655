import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Tools,BaseSlimPage, Translate, BaseForm, BaseInputString } from 'educoreapp2';
import ProductSearch from "../cmp/ProductSearch";
class EduShopAppNewListPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "EduShopNewListPage";
    this.module = "EduShopApp";
    this.list = [];
    this.listObj = null;
    window.App.cache={};
    window.App.cache.allProducts=[];
    this.state={isShowAccepted:false};
    this.preparedList=[];
  }
  clickButton(pageName) {
    this.openPage(pageName);
  }
  async getAllProductList(){
    if (!Tools.empty(window.App.cache.allProducts)){
      return window.App.cache.allProducts;
    }
    let resp = await new Promise((resolve, reject) => { window.App.API.exec(
      true,
      "shopapp",
      "getAllProducts",
      {  }
    ,function(ss){resolve(ss)},function(ss){reject(ss);});});
    let res=resp;
    window.App.cache.allProducts=res;
 //   console.log("RRRR",res);
    return res;
  }
  async searchProductInProducts(valueToSearch) {
    let res={};
   let allProducts=await this.getAllProductList(); 
 let pp=new ProductSearch(allProducts);   
 res=pp.searchProduct(valueToSearch); 
 //  console.log("xxx",valueToSearch,res);
   
   return res;
  }
  openPage(pageName) {
    window.App.changePage("EduShopApp", pageName, null);
  }
  initForm() {
    let this2=this;
    setTimeout(function(){
    this2.form.objs.list.setValue("Ser feta\n\
Jaja\n\
Borówki\n\
Ser żółty\n\
Banany\n\
Ser biały");
    },1000);
  }
  cleanString(str){
    str=str.trim();
    return str;
  }
  makeListFromString(str){
    let list=str.split('\n');
    let res=[];
    for(let k in list){
      let el=list[k];
      el=this.cleanString(el);
      res.push(el);
    }
    console.log(res);
    return res;
  }
  sortList(kk,cmp){
    let res=kk.sort(cmp);
    return res;
  }
  async tryMakeList(list){
    let res=[];
    let res2=[];
    for(let k in list){
      let el=list[k];
      let rr=await this.searchProductInProducts(el);
      rr.query=el;
      res.push(rr);
    }
    res2=this.sortList(res,function(a,b){
      if(a.name<b.name) {
        return -1;
      }else 
      if(a.name>b.name) {
        return 1;
      }

      return 0;
    });
    return res2;
  }
    genAcceptedList(){
    let res=[];
    for(let k in this.preparedList){
      let el=this.preparedList[k];
      if (!Tools.empty(el.name)){
        continue;
      }
      console.log(el);
      let key="listApp"+k;
      let label="Kategoria";
      let placeholder=label;
      let kid="kat"+k;
      let el2=(<tr key={key}><td>{el.query}</td>
      <td><BaseInputString  list="getCategory" name={kid} 
      id={kid} type="select" placeholder={placeholder}
        parent={this} require="true"/></td></tr>);
      res.push(el2);
      //res.appendChild(el);
    }
    let html=(<table style={{width:"100%"}}>
      <thead style={{width:"100%"}}> <tr style={{width:"100%"}}><th style={{width:"40%"}}>Nazwa</th><th>Kategoria</th><th>Usuń</th></tr>
      </thead>
      <tbody>
       {Object.values(res.map(xx => (
        xx
    )))}
    </tbody>
    </table>);
    return html;
  }
   butAct1 = async event => {
    event.preventDefault();
    console.log(this.form);
    let str=this.form.objs.list.value;
    let list=this.makeListFromString(str);
    list=await this.tryMakeList(list);
    this.preparedList=list;
    console.log('DDDd',list);
    this.setState({isShowAccepted:true});
  }
  butAct2 = event => {
    event.preventDefault();
    window.App.changePage("SSO", "register", []);
  }
  butAct3 = async event => {
    event.preventDefault();
    console.log(this.form);
    let str=this.form.objs.list.value;
    let list=this.makeListFromString(str);
    list=await this.tryMakeList(list);
    this.preparedList=list;
    console.log('DDDd',list);
  }
  butAct4 = async event => {
    event.preventDefault();
    console.log(this.form);
    let str=this.form.objs.list.value;
    let list=this.makeListFromString(str);
    list=await this.tryMakeList(list);
    this.preparedList=list;
    console.log('DDDd',list);
    this.setState({isShowAccepted:true});
  }
  render() {
    let TT = Translate.getT(window.App.lang, "EduShopApp", this.tag);
    let acceptHtml=(<BaseInputString type="text" name="list" id="list" label={TT.list.label} placeholder={TT.list.placeholder}
      parent={this} require="true" />);
      if (this.state.isShowAccepted){
        acceptHtml=this.genAcceptedList();
      }
      let displayNormal="flex";
    let displayStep1=!this.state.isShowAccepted?displayNormal:"none"; 
    let displayStep2=this.state.isShowAccepted?displayNormal:"none"; 
   
    console.log("displayStep1",displayStep1); 
    let butts = (<div className='row'><div style={{display:displayStep1}} className="row">
      <Button className='col-5' color="primary" onClick={this.butAct1}>Mapowanie</Button>
      <div className="col-2"></div>
      <Button className='col-5' color="primary" onClick={this.butAct2}>Wstecz</Button>
    </div> <div style={{display:displayStep2}} className="row">
      <Button className='col-5' color="primary" onClick={this.butAct3}>Dodaj</Button>
      <div className="col-2"></div>
      <Button className='col-5' color="primary" onClick={this.butAct4}>Wstecz</Button>
    </div> </div> );
    return (
      <BaseSlimPage isMenuShow={true} bgColor="white">
        <div className="row g-0 no-scroll">
          <BaseForm isLocal="true" extraButt={butts} label="Lista Zakupowa" module="" act="" className="row" parent={this}>
            {acceptHtml}
          </BaseForm>
          <div className="row g-0 h-20"></div>
        </div>
      </BaseSlimPage>
    );

  }
}

export default EduShopAppNewListPage;