import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {PageWithMenu,BaseSlimPage,BaseImg,BaseCard,BaseButton,Translate} from 'educoreapp2';
class EduShopAppHome2Page extends Component {
  constructor(){
    super();
    let this2=this;
    this.tag="EduShopAppHomePage";
    this.module="EduShopApp";
   setTimeout( () => {
    let xx="https://www.bizraport.pl/krs/0000818606/icefactory-poland-spolka-z-ograniczona-odpowiedzialnoscia";
   this2.loadCode(xx);
   },500); 
  }
  async wget2(url){
    let xx= await this.wget3(url);
    let res=xx.data.files;
    return res;
  }
  async wget3(url){
    return new Promise((resolve, reject) => {
      window.App.API.exec(false,"core", "wget", { "url": url, "typ": "html" }, (ss) => {
        resolve(ss);
      }, (ee) => {
        reject(ee);
      });
    });
  }
  extractTextBetweenTags(text, startTag, endTag) {
    const startIndex = text.indexOf(startTag);
  
    if (startIndex === -1) {
      return null;
    }
  
    const endIndex = text.indexOf(endTag, startIndex);
  
    if (endIndex === -1) {
      return null;
    }
  
    return text.substring(startIndex, endIndex);
  }
  async loadCode(url){
    let xx=await this.wget2(url); 
    let doc=document.getElementById("myFrame");
    let baseurl="https://www.bizraport.pl/";
    let mainhtml=xx.MAIN_HTML;
    mainhtml=this.extractTextBetweenTags(mainhtml,"<body","</body");
  mainhtml=mainhtml.replaceAll('href="','href="'+baseurl);
  mainhtml=mainhtml.replaceAll('src="','src="'+baseurl);
  doc.innerHTML=mainhtml;
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduShopApp",pageName,null);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"EduShopApp",this.tag);
   
  
      return (
      <div id="myFrame" style={{width:"100vw",height:"100vh"}}></div>  
      );

      
  }
}

export default EduShopAppHome2Page;