import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {BaseButton,Translate,BaseSlimPage} from 'educoreapp2';
class EduShopAppHomePage extends Component {
  constructor(){
    super();
    let this2=this;
    this.tag="EduShopAppHomePage";
    this.module="EduShopApp";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    setTimeout(function(){
    window.App.textToCommand.listen(this2,this2.tag);
    },500);
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduShopApp",pageName,null);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"EduShopApp",this.tag);
      const buttons = [
        { label: TT.shopping, page: 'edushopapp_shopping' },
        { label: TT.edit, page: 'edushopapp_edit' },
        { label: TT.new, page: 'edushopapp_new' }
      ];
   
  
      return (
        <BaseSlimPage isMenuShow={true} bgColor="white">
          <div className="row h-100 g-0 no-scroll">
          <div className="row g-0 h-20"></div>
            {buttons.map((button, index) => (
              <div key={index} className="row g-0 h-10">
                <BaseButton label={button.label} onClick={(event) => this.openPage(button.page)} />
              </div>
            ))}
          <div className="row g-0 h-20"></div>
          </div>
        </BaseSlimPage>
      );
      
      /*
    return (
      <BaseSlimPage isMenuShow={false} bgColor="white">
      <div className='row h-100 g-0 no-scroll'>
      <div className="row g-0 h-20"></div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.new_game} onClick={ (event) => this.openPage('edushopapp_new_game')}></BaseButton>
      </div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.change_lang} onClick={(event) =>window.App.changeLangToNext()}></BaseButton>
      </div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.how_to_play} onClick={(event) =>this.openPage('edushopapp_how_to_play')}></BaseButton>
      </div>
      <div className='row g-0 h-10'>
        <BaseButton label={TT.about_us}  onClick={(event) =>this.openPage('edushopapp_about_us')}></BaseButton>
      </div>
            <div className="row g-0 h-20"></div>
      </div>
 </BaseSlimPage>
 

    );
    */
    /*
<div className='row g-0 h-5'>
        <BaseButton label={TT.login} onClick={(event) =>this.openPage('edushopapp_login')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.register} onClick={(event) =>this.openPage('edushopapp_register')}></BaseButton>
      </div>

    */
  }
}

export default EduShopAppHomePage;