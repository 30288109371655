import { Component } from 'react';
//import en_GB_EduShopApp_HomePage_text1 from './pl_PL/pl_PL_EduShopApp_HomePage_text1.mp3';
//import en_GB_EduShopApp_HomePage_text2 from './pl_PL/pl_PL_EduShopApp_HomePage_text2.mp3';
import test from './pl_PL/test.mp3';
import en_GB_EduShopApp_LogoPage_text1 from './en_GB/en_GB_EduShopApp_LogoPage_text1.mp3';
import en_GB_EduShopApp_AboutUsPage_text1 from './en_GB/en_GB_EduShopApp_AboutUsPage_text1.mp3';

class TTR_En_GB extends Component {
    static reads = {
        EduShopApp: {
            EduShopAppLogoPage: {
                text1: {
                    mp3: en_GB_EduShopApp_LogoPage_text1,
                    text: "Welcome to Edupanel.pl. Let's play EduShopApp."
                }

            },
            EduShopAppHomePage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
                text2: {
                    mp3: test,
                    text: "Wybierz jedną z pozycji:- Nasze Cele,- Nasza Misja,-Zostań naszym mecenasem,-Gry na Android, oraz- Aplikacje na Android."
                }

            },
            EduShopAppPlayerWinPage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppPlayerLostPage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppPlayerDrawPage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppNewGamePage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppHelpPage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppHowToPlayPage: {
                text1: {
                    mp3: test,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            EduShopAppAboutUsPage: {
                text1: {
                    mp3:en_GB_EduShopApp_AboutUsPage_text1,
                    text: "Witamy w EduShopApp.pl!"
                },
            },
            Menu: {
                goals: "Nasze Cele",
                mission: "Nasza Misja",
                dotation: "Zostań naszym mecenasem",
                apps: "Aplikacje na Android",
                games: "Gry na Android"
            }
        }
    };

}
export default TTR_En_GB;