import React, { Component } from 'react';
import './App.css';
import {API,Tools,TextToCommand,Translate,APIServer} from 'educoreapp2';
import EduShopAppNewListPage from './Module/EduShopApp/Page/EduShopAppNewListPage';
import EduShopAppShoppingPage from './Module/EduShopApp/Page/EduShopAppShoppingPage';

import EduShopAppLogoPage from './Module/EduShopApp/Page/EduShopAppLogoPage';
import EduShopAppHomePage from './Module/EduShopApp/Page/EduShopAppHomePage';
import EduShopAppHome2Page from './Module/EduShopApp/Page/EduShopAppHome2Page';
import EduShopAppAboutUsPage from './Module/EduShopApp/Page/EduShopAppAboutUsPage';
import SSOLoginPage from './Module/SSO/Page/SSOLoginPage';
import NoPage from './Module/EduShopApp/Page/NoPage';
import AdvPage from './Module/EduShopApp/Page/AdvPage';
import FundPage from './Module/EduShopApp/Page/FundPage';
import NoInternetPage from './Module/EduShopApp/Page/NoInternetPage';
import UpdatePage from './Module/EduShopApp/Page/UpdatePage';
import NoSoundPage from './Module/EduShopApp/Page/NoSoundPage';
import { createBrowserHistory } from 'history';
import TextToRead from './TextToRead';
import TCT_edushopapp_Pl_PL from './Module/EduShopApp/TCT/TCT_Pl_PL';
import TCT_edushopapp_En_GB from './Module/EduShopApp/TCT/TCT_En_GB';
//import TCT_edushopapp_Ua_UA from './Module/EduShopApp/TCT/TCT_Ua_UA';
import logo from './img/logo.png';
import EduAppDb from './db/EduAppDb';
import APILocal from './APILocal';
import imgHome from './img/home.svg';
import imgShopping from './img/shopping.svg';
import imgEdit from './img/edit.svg';
import imgNewList from './img/add.svg';




function DynamicTag(props) {
    if (props.tag === "EduShopAppHomePage") {
        return (<EduShopAppHomePage></EduShopAppHomePage>);
    }
    else if (props.tag === "EduShopAppHome2Page") {
        return (<EduShopAppHome2Page></EduShopAppHome2Page>);
    } 
    else if (props.tag === "EduShopAppLogoPage") {
        return (<EduShopAppLogoPage></EduShopAppLogoPage>);
    }else if (props.tag === "EduShopAppNewListPage") {
        return (<EduShopAppNewListPage></EduShopAppNewListPage>);
    }
    else if (props.tag === "EduShopAppShoppingPage") {
        return (<EduShopAppShoppingPage></EduShopAppShoppingPage>);
    }
    
    else if (props.tag === "EduShopAppAboutUsPage") {
        return (<EduShopAppAboutUsPage></EduShopAppAboutUsPage>);
    }
    else if (props.tag === "NoSoundPage") {
        return (<NoSoundPage></NoSoundPage>);
    }
    else if (props.tag === "SSOLoginPage") {
        return (<SSOLoginPage />);
    }
    else if (props.tag === "AdvPage") {
        return (<AdvPage />);
    }
    else if (props.tag === "FundPage") {
        return (<FundPage />);
    }
    else if (props.tag === "NoInternetPage") {
        return (<NoInternetPage />);
    }
    else if (props.tag === "UpdatePage") {
        return (<UpdatePage />);
    }
    else {
        return (<NoPage></NoPage>);
    }
}

class App extends Component {
    PAGES_MAP = {
        ads:"AdvPage",
        fund:"FundPage",
        no_intenet:"NoInternetPage",
        update:"UpdatePage",
        "": "EduShopAppLogoPage",
        logo: "EduShopAppLogoPage",
        nosound: "NoSoundPage",
        home: "EduShopAppHomePage",
        edushopapp_edit:"EduShopAppNewListPage",
        edushopapp_shopping: "EduShopAppShoppingPage",
        edushopapp_about_us: "EduShopAppAboutUsPage",
        edushopapp_player_win: "EduShopAppPlayerWinPage",
        edushopapp_player_lost: "EduShopAppPlayerLostPage",
        edushopapp_player_draw: "EduShopAppPlayerDrawPage",
        edushopapp_how_to_play: "EduShopAppHowToPlayPage",
        login:"SSOLoginPage"

    };
    constructor() {
        super();
        Tools.GetDeviceId().then(id => {
            this.DeviceID = id;
        });
        this.langs=["pl_PL","en_GB","ua_UA"];
        window.App = this;
        this.textToCommand = {listen(xx,tt){}};
        this.lang = Tools.GetLang();
       // this.lang = "pl_PL";
        this.history = createBrowserHistory();
        let TT=Translate.getT(window.App.lang,"EduShopApp","EduShopAppHomePage");
        this.historyBrows=[];
        this.Config={
            isProd:false,
            backgroundColor:"white"
            ,logo:logo
            ,API_HOST_DEV:"http://apiplace.loc/"
        ,ver:"1.0"
        ,isMenuShow:false
        ,ApiFakerMethods:[]
        ,menu:[
            { icon: imgHome, title: 'Start', click:(event) =>window.App.changePage(null,"home") },
            { icon: imgShopping, title: 'Zakupy', click:(event) =>window.App.changePage(null,"edushopapp_shopping") },
            { icon: imgEdit, title: 'Edycja',click:(event) =>window.App.changePage(null,"edushopapp_edit") },
            { icon: imgNewList, title: 'Nowa lista',click:(event) =>window.App.changePage(null,"edushopapp_new") },
         
        ]
            ,menu2:[{ title: TT.login, action: (event) =>window.App.changePage(null,"login") },
            { title: TT.register, action: (event) =>window.App.changePage(null,"regiser") },
            { title: TT.logout, action: (event) =>window.App.changePage(null,"logout") }
        ]
        };
        window.App.Config.isMenuLeave = false;
        window.App.Config.isPlayAudio=false;
        this.tag = "EduShopAppLogoPage";
        this.isBlind = true;
        //this.isInteract=false;
        this.handleBackButton();
        let this2 = this;
        this.API = new API();
        async function aaa(){
            let xx=new EduAppDb();
            await xx.init();
        }
        aaa();
        this.APIServer=new APIServer();
        this.APILocal=new APILocal();
        
        /*
        this.API.exec("sso","getVer",null,(ss)=>{
            let xx=!Tools.empty(ss.data)&&!Tools.empty(ss.data.ver)?parseFloat(ss.data.ver):null;
            let ver=parseFloat(this2.Config.ver);
          
            if (Tools.empty(xx)){
                window.App.changePage(null,"no_internet");
                return;
            }else if (xx>ver){
                window.App.changePage(null,"update");
                return;
            }
            
           
        },(ee)=>{
            window.App.changePage(null,"no_internet");
        });
        */
    }
    getList(a,b){
        window.App.API.exec(true,"esa",a,{},(ss)=>{
            b(ss);
            
         },(ee)=>{
            
         }); 
        return [];
    }
    handleBackButton() {
        if (window.history && window.history.pushState) {
            let this2 = this;
            window.addEventListener('popstate', function () {
                let pageName = window.location.pathname.substring(1);
                this2.changePage(null, pageName);
            });
        }
    }
    TTCInit() {
        this.textToCommand.MAP = {
            pl_PL: TCT_edushopapp_Pl_PL.map,
            en_GB: TCT_edushopapp_En_GB.map,
            ua_UA: TCT_edushopapp_Pl_PL.map
        };
    }
    tryPlay() {
       // this.play();
    }
    play() {
        //this.textToRead.isInteract = true;
        //this.textToRead.play();
    }
    readText(modle, pageName, tag) {
        let this2 = this;
        setTimeout(() => {
         //   this2.textToRead.read(modle, pageName, tag);
          //  this2.tryPlay();
        }, 150);

    }
    render() {

        const customTag = this.tag;
        return (
            <div style={{ height: "100%", width: "100%"}}>
                <div className='row g-0' style={{ height: "100%" }}>
                    <DynamicTag tag={customTag} />
                </div>
        
            </div>
        );
        return (
            <div style={{ height: "100%", width: "100%"}}>
                <div className='row g-0' style={{ height: "100%" }}>
                    <DynamicTag tag={customTag} />
                </div>
                <div><TextToCommand parent={this}></TextToCommand>
                    <TextToRead parent={this}></TextToRead>
                </div>
            </div>
        );

    }
    getTag(pageName) {
        return this.PAGES_MAP[pageName];
    }
    goBack(){
        let n=this.historyBrows.length;
        let k=n-2;
        if (k<0){
            k=0;
        }
        let p=this.historyBrows[k];
        this.changePage(p.module,p.pageName);
    }
    goAds(module,pageName,params){
        if (["ads"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["AdvPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["edushopapp_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
        this.changePage(null,"ads",params);
        return true;
    }
    goFund(module,pageName,params){
        if (["fund"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["FundPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["edushopapp_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        //window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
       // this.changePage(null,"fund",params);
        return true;
    }
    isLogin(){
        let k=!Tools.empty(this.profile);
        return k;
    }
   hasAccess(module, pageName, params){
    if (["home","login"].indexOf(pageName)>=0){
        return true;
    }
    if (Tools.empty(this.profile)){
        return false;
    }
    console.log("pageName",pageName);
    return true;
   }
    changePage(module, pageName, params) {
        if (!this.hasAccess(module, pageName, params)){
            this.changePage(module,"login");
            return;
        }
        let tag = this.getTag(pageName);
        console.log(pageName,tag);
        this.tag = tag;
        let path = "/" + pageName;
        this.historyBrows.push({module:module,pageName:pageName});
        this.forceUpdate();
    }
    changeLangToNext(){
        let i=this.langs.indexOf(this.lang);
        let n=this.langs.length;
        let k=(i+1)%n;
        this.lang=this.langs[k];
        this.changeLang(this.lang);    
    }
    changeLang(lang) {
        this.lang = lang;
        this.forceUpdate();
    }
}

export default App;
