
import DbModel from './DbModel';

class ESAProductCategory extends DbModel{
    constructor(){
        super();
        this.tableName="esaproductcategory";
        this.props={
            id: {
                type: 'string'
                ,maxLength: 40
                ,require:true
            },
            name: {
                type: 'string'
                ,maxLength:50
                ,minLength:3
                ,require:true
            },
            description: {
                type: 'string'
                ,maxLength:255
                ,minLength:3
                ,require:true
            },
            timestamp: {
                type: 'string',
                format: 'date-time'
                ,require:false
            }
        };
    }
    
    async createTable(){
        await this.db.createTable(this.tableName,"id",this.props,["id","name","description"]);
    }
}
export default ESAProductCategory;