import EduDb from './EduDb';
import ESAUser from "./ESAUser";
import ESASession from "./ESASession";

import ESAProductCategory from "./ESAProductCategory";
import ESAProduct from "./ESAProduct";
import ESAList from "./ESAList";
import ESAListItem from "./ESAListItem";

import DBMethods from './DBMethods'; 

class LoadData{
    user=[{id:"7fdb68b10e85178fe4c04854f459fe0092784750404310d1bdf24af82ab8f33d","login":"admin","password":"1234","devid":"7fdb68b10e85178fe4c04854f459fe0092784750404310d1bdf24af82ab8f33d"},
        {id:"27a539af430491a451cbb8f634cb6d813e3ef453433436ccbd3acdae809a3310","login":"test","password":"b23c416a8b66194f5026fc3083def92938d682269a1eb9773d108232f6447934"},
        {id:"ec24c887007fa5bf31986a076628cc86c4857e096df79e7f8ae33e8a017df43c","login":"test2","password":"1234"}
    ];
    category=[
        {'id':'0000325aba58032320edc30b6faf7dbf38863262','name':'Bez Kategorii'},
        {'id':'0b7fe9136cf08edafdd31b2f78dae994673a7360','name':'Pieczywo'},
        {'id':'f67192ae00289048f5d6602a6ff319ac4329c56a','name':'Nabiał'},
        {'id':'f012f0f2b4133014735c3d3f5517918c430e51e7','name':'Mięso'},
        {'id':'9395c90178955c0a72196f141ebd6a19ff4dae5a','name':'Warzywa'},
        {'id':'e60e0b673b2bffa517fa5aec04a7b8ee52ef25a8','name':'Owoce'},
        {'id':'9ad1eb5106883f8eeece530e488e0fe670dd1868','name':'Makarony'},
        {'id':'9372eabc50c82305a4832213742c70a8a556542c','name':'Zboża'},
        {'id':'32326af57b549544d5d4793d527b38a0e586f555','name':'Przyprawy'},
        {'id':'8a3c7c5e8f6fe0b6e6bc5c9a8facfb668739c340','name':'Tłuszcze'},
        {'id':'eb901c46cb31117b420b107a7e382912f0ee0007','name':'Słodycze'},
        {'id':'1680875aba58032370edc30b6faf7dbf5f873b01','name':'Napoje'},
        {'id':'327732957373ecdced9739227238178120121122','name':'Leki'}
    ];

product=[{'id':'9572c09142577e69be234287f78733ee3c1c1ef2','name':'Chleb pszenny','categoryId':'0b7fe9136cf08edafdd31b2f78dae994673a7360'},
    {'id':'f43a6f828925beeeb70cb34362a9ac2c3fecfade','name':'Masło','categoryId':'f67192ae00289048f5d6602a6ff319ac4329c56a'},
    {'id':'5964e469ff42901886204adc72e9c110c80d3004','name':'Jajka','categoryId':'f67192ae00289048f5d6602a6ff319ac4329c56a'},
    {'id':'e17e8171cb41a8d4c742c85594216847234f747f','name':'Mleko','categoryId':'f67192ae00289048f5d6602a6ff319ac4329c56a'},
    {'id':'ceb47679b8978ca98f7ff2374311f30957ab0f32','name':'Ser żółty','categoryId':'f67192ae00289048f5d6602a6ff319ac4329c56a'},
    {'id':'002093d63800bf9f643ac268de507b68b7a5e5f1','name':'Szynka wieprzowa','categoryId':'f012f0f2b4133014735c3d3f5517918c430e51e7'},
    {'id':'6a28e49e2a10e5e4a44cebfb9bf1a97cb0d39d33','name':'Kurczak','categoryId':'f012f0f2b4133014735c3d3f5517918c430e51e7'},
    {'id':'bb1660641428ebcb4d47b9f04eb9088d62662ac8','name':'Ziemniaki','categoryId':'9395c90178955c0a72196f141ebd6a19ff4dae5a'},
    {'id':'f178d62db8b6b6e3ab22f0735f2cc9743b7dd4ce','name':'Marchew','categoryId':'9395c90178955c0a72196f141ebd6a19ff4dae5a'},
    {'id':'a3e374a7c197c55a9ab23278d6e501427786a490','name':'Cebula','categoryId':'9395c90178955c0a72196f141ebd6a19ff4dae5a'},
    {'id':'141412b973a0839f7c84664a4d71c54a75f00559','name':'Jabłka','categoryId':'e60e0b673b2bffa517fa5aec04a7b8ee52ef25a8'},
    {'id':'1699c7c998db9a5537775c7ac012a17228097351','name':'Banany','categoryId':'e60e0b673b2bffa517fa5aec04a7b8ee52ef25a8'},
    {'id':'16b258b2875e556a0adee961d72b99cfcf5ed6dc','name':'Makaron','categoryId':'9ad1eb5106883f8eeece530e488e0fe670dd1868'},
    {'id':'5bd89a4d497f8d47d51324f6a798674ef2023897','name':'Ryż','categoryId':'9372eabc50c82305a4832213742c70a8a556542c'},
    {'id':'41015f6f862b2cb1b38eef5e6b90727471da9840','name':'Cukier','categoryId':'32326af57b549544d5d4793d527b38a0e586f555'},
    {'id':'8475fb854275269a076c478916e1f08949179e36','name':'Sól','categoryId':'32326af57b549544d5d4793d527b38a0e586f555'},
    {'id':'e0cf1ea7ca5140e4eeca836f3439acb9e7556bbe','name':'Olej roślinny','categoryId':'8a3c7c5e8f6fe0b6e6bc5c9a8facfb668739c340'},
    {'id':'2f0ff31627be97a463777441d1f35ccb5d27cca9','name':'Miód','categoryId':'eb901c46cb31117b420b107a7e382912f0ee0007'},
    {'id':'4ddbf965a0a9c4348a6c0ca9919e023d9c7ac36e','name':'Herbata','categoryId':'1680875aba58032370edc30b6faf7dbf5f873b01'},
    {'id':'022adc2624d9f8c78a02b8add2b214b86b3a20a9','name':'Kawa','categoryId':'1680875aba58032370edc30b6faf7dbf5f873b01'}];
list=[{'id':'022adc2624d9f8c78a02b8add2b214b86b3a20a9','name':'Moja Lista',"userId":"7fdb68b10e85178fe4c04854f459fe0092784750404310d1bdf24af82ab8f33d"}];
listItem=[
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'5964e469ff42901886204adc72e9c110c80d3004'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'e17e8171cb41a8d4c742c85594216847234f747f'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'ceb47679b8978ca98f7ff2374311f30957ab0f32'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'002093d63800bf9f643ac268de507b68b7a5e5f1'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'6a28e49e2a10e5e4a44cebfb9bf1a97cb0d39d33'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'bb1660641428ebcb4d47b9f04eb9088d62662ac8'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'f178d62db8b6b6e3ab22f0735f2cc9743b7dd4ce'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'a3e374a7c197c55a9ab23278d6e501427786a490'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'141412b973a0839f7c84664a4d71c54a75f00559'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'1699c7c998db9a5537775c7ac012a17228097351'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'16b258b2875e556a0adee961d72b99cfcf5ed6dc'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'5bd89a4d497f8d47d51324f6a798674ef2023897'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'41015f6f862b2cb1b38eef5e6b90727471da9840'},
    {'listId':"022adc2624d9f8c78a02b8add2b214b86b3a20a9",'productId':'8475fb854275269a076c478916e1f08949179e36'}
  


];
        async getUser(){
            return this.user;
        }
        async getCategory(){
            return this.category;
        }
        async getProduct(){
            return this.product;
        }
        async getList(){
            return this.list;
        }
        async getListItem(){
            return this.listItem;
        }
    }
class EduAppDb{
    async init() {
    let db=new EduDb();
    window.App.db=db;
    let loader=new LoadData();
    await db.run();    
    let esaUser=new ESAUser();
    await esaUser.createTable();
    let dd=await loader.getUser();
    await esaUser.load(dd);
    
    let esaSession=new ESASession();
    await esaSession.createTable();
    
    let esaProductCategory=new ESAProductCategory();
    await esaProductCategory.createTable();
    dd=await loader.getCategory();
    await esaProductCategory.load(dd);
    let esaProduct=new ESAProduct();
    await esaProduct.createTable();
    dd=await loader.getProduct();
    await esaProduct.load(dd);
    
    let esaList=new ESAList();
    await esaList.createTable();
    dd=await loader.getList();
    await esaList.load(dd);

    let esaListItem=new ESAListItem();
    await esaListItem.createTable();
    dd=await loader.getListItem();
    await esaListItem.load(dd);
    let dBMethods=new DBMethods();
    let xx=null;
    let login="test";
    let devid="7fdb68b10e85178fe4c04854f459fe0092784750404310d1bdf24af82ab8f33d";
    let password="0de59d3dc92eaa899ec5bbeace98eae57f5fdbd0da3ebcdb1569937022f4daa2";
    xx=await dBMethods.autoLogin(devid);
    let sessid=window.App.profile.sessid;
    let listId="022adc2624d9f8c78a02b8add2b214b86b3a20a9";
    xx=await dBMethods.getList(devid,sessid,listId);
    //let res= await dBMethods.loginToSystem(devid,login,password);
    

    // password="0de59d3dc92eaa899ec5bbeace98eae57f5fdbd0da3ebcdb1569937022f4daa2";
   // let list=await esaUser.select();
    //console.log("dddd",list);
    } 
}
export default EduAppDb;