
import DbModel from './DbModel';

class ESAUser extends DbModel{
    constructor(){
        super();
        this.tableName="esauser";
        this.props={
            id: {
                type: 'string',
                maxLength: 40
                ,require:true
            },
            devid: {
                type: 'string',
                maxLength: 40
                ,require:true
            },
            login: {
                type: 'string'
                ,maxLength:50
                ,minLength:3
                ,require:true
            },
            password: {
                type: 'string'
                ,maxLength:80
                ,minLength:3
                ,require:true
            }
        };
    }
    
    async createTable(){
        await this.db.createTable(this.tableName,"id",this.props,["id","login","password","devid"]);
    }
}
export default ESAUser;