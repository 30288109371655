import { addRxPlugin } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { createRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { Tools } from 'educoreapp2';
import { array } from 'prop-types';
import EduTableTemp from './EduTableTemp';
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBDevModePlugin);

class EduDb {
    async connect() {
        this.db = await createRxDatabase({
            name: 'mydatabase',
            storage: getRxStorageDexie()
        });

    }
    async mapRequire(prop) {
        let res = [];
        for (let k in prop) {
            res.push(k);
        }
        return res;
    }
    async createTable(p_tableName, p_primaryKey, p_properties, p_required) {
        await this.db.addCollections({
            [p_tableName]: {
                schema: {
                    version: 0,
                    primaryKey: p_primaryKey,
                    type: 'object',
                    properties: p_properties,
                    required: p_required
                }
            }
        });
    }
    async drop(tableName) {
        await this.db[tableName].remove();
        //await this.db[tableName].cleanup();
    }
    async init() {
    }
    async insert(tableName, val) {
        //   val.id=null;
        if (Tools.empty(val.id)) {
            let str = JSON.stringify(val);
            val.id = Tools.SHA1(str);
        }

        let res = await this.db[tableName].insert(val);
        return res;
    }

    async clean() {
        await this.db.remove();
    }
    async load() {
        let myDocument = await this.db.todos.insert({
            id: 'todo1',
            name: 'Learn RxDB',
            done: false,
            timestamp: new Date().toISOString()
        });
        myDocument = await this.db.todos.insert({
            id: 'todo2',
            name: 'ccjcijfifjid',
            done: false,
            timestamp: new Date().toISOString()
        });
    }
    /*
    const observable = db.todos.find({
      selector: {
          done: {
              $eq: false
          }
      }
  }).$;
  */
    async subQuery(tableName, where) {
        let sel = {};
        let query = this.db[tableName];
        if (!Tools.empty(where)) {
            sel = { selector: {} };
            where.forEach(el => {
                let name = el.name;
                let type = "$eq";
                let val = el.val;
                sel["selector"][name] = {};
                sel["selector"][name][type] = val;
            });
        }
        if (Tools.empty(sel)) {
            query = query.find();
        } else {
            query = query.find(sel);
        }
        let results = await query.exec();
        return results;
    }
    mapJSON(result, s,MAP) {
        let res = [];
        let sub = Tools.empty(s) ? "" : s + "_";
        let MAP2 = ["_meta", "_attachments", "_deleted", "_rev"];
        result.forEach(el => {
            let rr = {};
            let el2 = el._data;
            for (let k in el2) {
                if (MAP2.indexOf(k) >= 0) {
                    continue;
                }       
                rr[sub + k] = el2[k];
            }
            if (Tools.empty(MAP)){
            res.push(rr);
                return;
            }
            let rr2={};
            for(let k2 in MAP){
                let el2=MAP[k2];
                rr2[k2]=rr[el2];
            }
            res[el2.id]=rr2;
        });
        return res;

    }
    async selectJoins(res, where, joins,MAP) {
        let this2 = this;
        let res2 = {};
        let rr = res;
        // return;
        for (let kk in joins) {
            let el = joins[kk];
            const tableName = el.tableName;
            const tabWhere = where[el.whereName];
            const tt = await this2.subQuery(tableName, tabWhere);
            let this3 = this2;
            let jj = [];
            for (let iz in rr) {
                let zz = rr[iz];
                const xx = tt.filter(yy => yy[el.right] === zz[el.left]);
                let map3 = this3.mapJSON([zz]);
                let map2 = this3.mapJSON(xx, el.res);
                res2 = Object.assign({}, map3[0], map2[0]);
                jj.push(res2);
            }
            let prop = EduTableTemp.mapProps(jj[0]);
            let aa = new EduTableTemp(prop);
            await aa.createTable();
            await aa.load(jj);
            rr = await aa.selectDB();
            await aa.drop();

        }
        rr = rr.flat();
        let rr2 = this.mapJSON(rr,null,MAP);
        return rr2;
    }

    async select(tableName, where, joins,MAP) {
        let tabWhere = [];
        let res = [];
        if (!Tools.empty(where) && !Tools.empty(where["tab"])) {
            tabWhere = where["tab"];
        }
        let tab = await this.subQuery(tableName, tabWhere);
        let resret = this.mapJSON(tab, "");
        if (Tools.empty(joins)) {
            return resret;
        }
        res = await this.selectJoins(tab, where, joins,MAP);
        return res;


    }
    async selectDB(tableName, where) {
        let tabWhere = [];
        let res = [];
        if (!Tools.empty(where) && !Tools.empty(where["tab"])) {
            tabWhere = where["tab"];
        }
        let tab = await this.subQuery(tableName, tabWhere);
        return tab;
    }
    async run() {
        await this.connect();
        await this.clean();
        await this.connect();
        /*
        await this.init();
        await this.load();
        await this.select();
        */
    }
}
export default EduDb;