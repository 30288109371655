import React, { Component } from 'react';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class BaseAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.props=props;
    this.state = {
      inputValue: '',
      suggestions: [],
      loading: false
    };

  }

  componentDidMount() {
    this.fetchSuggestions(this.state.inputValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.list !== this.props.list || prevState.inputValue !== this.state.inputValue) {
      this.fetchSuggestions(this.state.inputValue);
    }
  }

  fetchSuggestions = (inputValue) => {
    if (inputValue.length > 2) {
      this.setState({ loading: true });
      const list = this.props.list;
      const filteredList = list.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()));
      this.setState({ suggestions: filteredList, loading: false });
    }
  };

  handleInputChange = (event) => {
    this.setState({
      inputValue: event.target.value
    });
  };

  handleSuggestionClick = (id) => {
    const selectedSuggestion = this.state.suggestions.find(s => s.id === id);
    this.props.onSelect(id);
    this.setState({
      inputValue: selectedSuggestion.name,
      suggestions: []
    });
  };

  render() {
    const { inputValue, suggestions, loading } = this.state;

    return (
      <Form.Group>
        <Form.Control
          type="text"
          className="form-control"
          placeholder="Search..."
          value={inputValue}
          onChange={this.handleInputChange}
          disabled={loading}
        />
        {loading ? (
          <div className="spinner-border spinner-border-sm mr-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="autocomplete-suggestions">
            {suggestions.map(suggestion => (
              <div
                key={suggestion.id}
                onClick={() => this.handleSuggestionClick(suggestion.id)}
                className="suggestion"
              >
                {suggestion.name}
              </div>
            ))}
          </div>
        )}
      </Form.Group>
    );
  }
}

export default BaseAutocomplete;
 