import React from 'react';
import { Table, Button } from 'react-bootstrap';
import './BaseList.css';

class BaseList extends React.Component {
  constructor(props) {
    super(props);
    this.props=props;
    this.props.parent.listObj=this;
    this.state = {
      products: {},
    };
  }
  loadList(data){
    this.setState({products:data});

  }
  showHideDitail=(id)=>{
    const stat = !this.state.products[id].isShowDitail;
    const prods = { ...this.state.products };
    prods[id].isShowDitail = stat;
    this.setState({
      products: prods
    });
  }
  onMouseEnter=(id)=>{
    this.showHideDitail(id);
  }
  onMouseLeave=(id)=>{
    this.showHideDitail(id);
  }

  handleMarkAsPurchased = (id) => {
    const stat = !this.state.products[id].purchased;
    const prods = { ...this.state.products };
    prods[id].purchased = stat;
    this.setState({
      products: prods
    });
  }

  render() {
    return (
      <Table striped bordered hover>
        <thead>
        </thead>
        <tbody>
          {Object.values(this.state.products).map(product => (
            <tr key={product.id}>
              <td>
                <Button
                  className="btn"
                  id={"btn"+product.id}
                  onMouseEnter={(e) => { e.preventDefault(); this.onMouseEnter(product.id); }}
                  onMouseLeave={(e) => { e.preventDefault(); this.onMouseLeave(product.id); }}
                  onClick={(e) => { e.preventDefault(); this.handleMarkAsPurchased(product.id); }}
                  style={{ backgroundColor: product.purchased ? 'green' : 'whitesmoke', width: '100%', color: product.purchased ? 'white' : 'black' }}
                >
                  {product.name}
                  <br />
                  <span  style={{display:product.isShowDitail?"block":"none",fontSize:"small"}}>{product.category}</span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default BaseList;