import React, { Component } from 'react';
import {PageWithMenu,BaseSlimPage,BaseImg,BaseCard,BaseButton,Translate,LogoEdupanel} from 'educoreapp2';
import img404 from '../../../img/update.png';
class UpdatePage extends Component {
    constructor(){
    super();
  //  window.App.textToCommand.listen(this,"nopage");
  }
  back(){
    window.App.goBack();
  }
  render() {    
  //    let TT=Translate.getT(window.App.lang,"EduShopApp","HomePage");
  let TT2=Translate.getT(window.App.lang,"EduShopApp","GameFinish");
   
    return (
      <BaseSlimPage isMenuShow={false} bgColor="white">
      <div className='row h-100 g-0'>
      <div style={{height:"100%",width:"100%"}}>
        <div style={{height:"90%",width:"100%"}}>
          <BaseImg backgroundColor={window.App.Config.backgroundColor} width="100%" height="100%" photo={img404} />
          </div>
      </div>
      </div>
 </BaseSlimPage>
    );
  }
}

export default UpdatePage;