import React, { Component } from 'react';
import { Form, Button, Alert } from 'reactstrap';
import {Tools,BaseImg} from 'educoreapp2';
class BaseForm extends Component {
    constructor(props){
        super();
        this.props=props;
        this.extraButt=props.extraButt;
        this.className=!Tools.empty(props.className)?props.className:"row";
        this.label=!Tools.empty(props.label)?props.label:"";
        this.className="eduform "+this.className;
        this.module=props.module;
        this.act=props.act;
        this.objs={};
        let this2=this;
        setTimeout(()=>{
          this2.props.parent.form=this2;
          this2.props.parent.initForm();
        },240);
        this.state = {
          components: [],
        };

    }
    init(data){
      
      for (let k in data){
        if (Tools.empty(this.objs[k])){
          continue;
        }
        
        this.objs[k].setValue(data[k]);
      }
      
    }
    async valid(){
      let isValid=true;
      for (let k in this.objs){
        let el=this.objs[k];
        let v= await el.valid();
        isValid=isValid&&v;
      }
      return isValid;
    }
    addObj(obj){
      this.objs[obj.states.name]=obj;
    }
    async getData(){
      let res={};
      for (let k in this.objs){
        let el=this.objs[k];
        let v=el.getValue();
        res[k]=v;
      }
      return res;
    }
    async setErrorBadLoginOrPasswd(){
      for (let k in this.objs){
        let el=this.objs[k];
        el.setError('BAD_LOGIN_OR_PASSWORD');
      }
      
    }
    async setError(ee){
      if (ee.code!=500){
        return;
      }
      if (ee.error.type=='BAD_LOGIN_OR_PASSWORD'){
        await this.setErrorBadLoginOrPasswd();
        return;
      }
    }
    async handle2(){
      let x=await this.valid();
      if (!x){
        return;
      }
      let params= await this.getData();
      params=this.props.afterValid(params);
      let this2=this;
      window.App.API.exec(true,this.module,this.act,params,(ss)=>{
        let params=[];
        this2.props.onSucc(ss.data);
        
     },(ee)=>{
        this2.setError(ee);
     });
    }
    handleSubmit = event => {
      event.preventDefault();
      this.handle2();
    }
    
  submit(){
    this.handle2();
  }
  render() { 
    let error=null;   
    let lab1=this.props.lab1;
    let label=this.label;
    let className2=this.className;
    let extraButt=!Tools.empty(this.extraButt)?this.extraButt:(<Button color="primary" type="submit">{lab1}</Button>);
    if (Tools.empty(lab1)){
      lab1="Login";
    }
    return (
      <Form className={className2}  onSubmit={this.handleSubmit}>
        <div className='eduFormHeader' style={{display:"inline-flex",width:"100%"}}>
         {/* <BaseImg height="3em" width="3em" photo={window.App.Config.logo} /> */}
          <div style={{textAlign:"center",fontSize:"2em",width:"100%"}}>{label}</div>
         {/* <BaseImg height="3em" width="3em" photo={window.App.Config.logo} /> */}
        </div>
      <div className='eduFormBody'>
      <div style={{padding:"20px"}}>
      {error && <Alert color="danger">{error}</Alert>}
      {this.props.children}
      </div></div>
      <div className='eduFormFooter'>
        <div style={{padding:"20px"}}>
     {extraButt}
     </div>
     </div>
    </Form>
    );
  }
}

export default BaseForm;