import React, { Component } from 'react';
import {ApiCode,Tools} from 'educoreapp2';
import DBMethods from './db/DBMethods';

class APILocal extends Component {
  constructor(props) {
    super();
    this.props = props;
   
    this.api = this.getAPI();
  }
  static IsProd() {
    //console.log(window.App.Config.isProd);
    return window.App.Config.isProd;
    //console.log(window.location.host);
    return ["localhost", "edumag.edupanel.pl", "edupanel.pl"].indexOf(window.location.host) >= 0;
  }
  static IsDev() {
    return window.App.Config.isDev;
  }
  getAPI() {

    if (APILocal.IsProd()) {
      return window.App.Config.API_HOST_PROD;
    } else {
      return window.App.Config.API_HOST_DEV;
    }
  }
  async addBaseParams(params) {
    if (Tools.empty(params)) {
      params = {};
    }
    if (!(Tools.IsArray(params) || Tools.IsObject(params))) {
      //console.log(params);
      return null;
    }

    params.devid = await Tools.GetDeviceId();
    params.sessid=Tools.LSGet("sessid");
    return params;
  }
  async exec(module, name, params, success, error) {
    console.log("DUPA EXEC");
    //let path = this.api + "/" + module + "/" + name;
    params = await this.addBaseParams(params);
    let dbMet=new DBMethods();
    dbMet.exec(module, name, params, success, error);
    return;
  }
  render() {
    return (<div></div>
    );
  }
}

export default APILocal;