import React, { Component} from 'react';
import { Button} from 'reactstrap';
import { Translate, BaseInputString, Tools, BaseImg } from 'educoreapp2';
import BaseForm  from './BaseForm';
class SSOLoginForm extends Component {
  constructor(props) {
    super(props);
    this.tag = "SSOLoginForm";
    this.className = !Tools.empty(props.className) ? props.className : "row";
    console.log("AAZZZ", this.className, props.className);
    this.state = {
      login: 'test',
      password: 'test222TEST!',
      error: null
    };
    this.form = { vals: { login: "" } };
    let this2 = this;
    setTimeout(function () { this2.initForm(); }, 1000);
  }
  initForm() {
    let st = this.state;
    let form = this.form;
    for (let k in st) {
      if (typeof form.objs[k] === "undefined") {
        continue;
      }
      console.log(k);
      form.objs[k].setValue(st[k]);
    }
    console.log(this.form);
  }
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();


  }
  succ(ss) {
    let this2 = this;
    console.log("aaaa",ss);
    let ss2 = { code: 200, data: ss };
    window.App.loginToSystem(ss2, function () {
      let params = [];
      console.log("DDD");
      window.App.changePage("Place", "home", params);
    }, function (ee) {
      console.log(this2.form, this2);
    });
  }
  butAct1= event => {
    event.preventDefault();
    this.form.submit();
  }
  butAct2= event => {
    event.preventDefault();
    window.App.changePage("SSO", "register", []);
  }
  afterValid(pp){
    pp.password=Tools.SHA1(pp.password);
    return pp;
  }

  render() {
    let className3=this.className;
    let TT = Translate.getT(window.App.lang, "SSO", this.tag);
    let butts=(<div className='row'>
      <Button className='col-5' color="primary" onClick={this.butAct1}>Login</Button>
      <div className="col-2"></div>
      <Button className='col-5' color="primary" onClick={this.butAct2}>Rejestracja</Button>
    </div>);
    return (
      <BaseForm className="row" parent={this}
        onSucc={this.succ} act="loginToSystem" module="sso"
        extraButt={butts}
        afterValid={this.afterValid}
        label={TT.titleForm}
      >
        
        <BaseInputString name="login" id="login" label={TT.login.label} placeholder={TT.login.placeholder}
          parent={this} require="true" minlength="3" maxlength="20" />
        <BaseInputString type="password" name="password" id="password" label={TT.password.label} placeholder={TT.password.placeholder}
          parent={this} require="true" minlength="4" maxlength="100" />

      </BaseForm>
    );
  }
}

export default SSOLoginForm;