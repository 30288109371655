
import DbModel from './DbModel';
import {Tools} from 'educoreapp2';

class EduTableTemp extends DbModel{
    constructor(props){
        super();
        this.tableName="temp123";
        this.props=props;
    }
    static mapProps(pp){
        let res={};
        for(let k in pp){
            let el=pp[k];
            switch (typeof el) {
                case "string":res[k]={type: 'string',maxLength:255};                    
                    break;
            
                default:
                    break;
            }
        }
        return res;



    }
    async createTable(){
        let props={
            id: {
                type: 'string',
                maxLength: 40
            },
            name: {
                type: 'string'
                ,maxLength:50
                ,minLength:3
            },
            prod_categoryId: {
                type: 'string'
                ,maxLength:50
                ,minLength:3
            }
        };
        props=Tools.empty(this.props)?props:this.props;
        let req=await this.db.mapRequire(props);
        await this.db.createTable(this.tableName,"id",props,req);
    }
}
export default EduTableTemp;