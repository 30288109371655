import React, { Component } from 'react';
import {EduApp,Tools} from 'educoreapp2';
class ApiFakerMethods extends Component{
    sso_loginToSystem(module, name, params, success, error){
        let xx={code:200,data:{
          sedsid:"2323923i239djdjdfifdj",first_name:"xxx",last_name:"zzz"
        ,last5places:{}
        }
        };
success(xx);
    }
  async  run(module, name, params, success, error){
    console.log("zzzz",name,module);
    this.sso_loginToSystem(module, name, params, success, error);
  }
}
export default ApiFakerMethods;
