import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {PageWithMenu,BaseSlimPage,BaseImg,BaseCard,BaseButton,Translate,BaseForm,BaseInputString} from 'educoreapp2';
import BaseAutocomplete from './BaseAutocomplete';
import BaseList from './BaseList';

class EduShopAppShoppingPage extends Component {
  constructor(){
    super();
    let this2=this;
    this.tag="EduShopNewListPage";
    this.module="EduShopApp";
    this.list=[];
    this.listObj=null;
    setTimeout(() => {
      let this3=this2;
      window.App.API.exec(true,"shopapp","getList"
        ,{"listId":"022adc2624d9f8c78a02b8add2b214b86b3a20a9"},(ss)=>{
        console.log("sss",ss);
        this3.listObj.loadList(ss.list);
      },(ee)=>{
        console.log("EEEEEEEEEEEEEEEEEE",ee);
      });

    },500);

    }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduShopApp",pageName,null);
    }
    initForm(){
      
    }
    addToList(val){
      console.log('vvvv',val);
      this.list.push(val);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"EduShopApp",this.tag);
      let list = [
        { id: 1, name: 'Apple' },
        { id: 2, name: 'Banana' },
        { id: 3, name: 'Cherry' },
        { id: 4, name: 'Cherry2' },
        { id: 5, name: 'Cherry3' },
        { id: 6, name: 'Durian' },
        { id: 7, name: 'Elderberry' }
      ];
      
  
      return (
        <BaseSlimPage isMenuShow={true} bgColor="white">
          <div className="row g-0 no-scroll">
            <BaseForm isLocal="true" label="Lista zakupowa" module="" act="" className="row" parent={this}>
            <BaseList parent={this}></BaseList>
            <BaseAutocomplete list={list} onSelect={this.addToList}></BaseAutocomplete>
       
     {/*  <BaseInputString name="login" id="login" label={TT.login.label} placeholder={TT.login.placeholder} 
        parent={this} require="true" minlength="5" maxlength="20"/>
        <BaseInputString type="password" name="passwd" id="passwd" label={TT.passwd.label} placeholder={TT.passwd.placeholder} 
        parent={this} require="true" minlength="5" maxlength="20"/>
     */}
      </BaseForm>
          <div className="row g-0 h-20"></div>
          </div>
        </BaseSlimPage>
      );
      
  }
}

export default EduShopAppShoppingPage;