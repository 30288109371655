import { Component } from 'react';
class Translate_ua_UA extends Component {
    static Trans = {
        Edupanel: {
            alts: {
                "/imgs/logo.png": "Logo Edupanel.pl",
                "/imgs/flags/EN.svg": "Tłumacz na Angielski",
                "/imgs/flags/PL.svg": "Tłumacz na Polski",
                "/imgs/www/polPriv.png": "Rysunek przedstawiający kota",
                "/imgs/www/home.png": "Rysunek przedstawiający kota",
                "/imgs/www/goals.png": "Rysunek przedstawiający kota",
                "/imgs/www/mission.png": "Rysunek przedstawiający kota",
                "/imgs/www/dotation.png": "Rysunek przedstawiający kota",
                "/imgs/www/apps.png": "Rysunek przedstawiający kota"
            },
            "no_page":{
                "title":"Brak Strony"
            }
        },
        SSO:{
            SSOLoginForm:{
                login:{
                    label:"Логін",
                    placeholder:"Логін"
                },
                passwd:{
                    label:"Пароль",
                    placeholder:"Пароль"
                },
                loginToSystem:{
                    label:"Увійти в систему"
                },
                register:{
                    label:"Зареєструватися"
                },
            }
        },
        EduShopApp: {
            EduShopAppHomePage: {
                new_game: "Нова гра",
                change_lang: "Змінити мову",
                how_to_play: "Як грати",
                about_us: "Про нас"
            },
            NoSoundPage:{
                no_sound:"Натисніть кнопку, щоб продовжити"
            },
            EduShopAppAboutUsPage: {
                title: "Про нас",
                text: "Ми створюємо високоякісні освітні додатки та ігри для людей з інвалідністю, особливо для незрячих. Наша мета – полегшити життя та розширити знання наших користувачів. Ми розуміємо, що розробка програмного забезпечення вимагає фінансових ресурсів, тому ми потребуємо підтримки, щоб продовжувати створювати інноваційні рішення. Ми вдячні за будь-яку допомогу та фінансову підтримку, яку ми отримуємо. Добродійники, які розуміють важливість нашої місії, сприяють розвитку наших проектів і дозволяють нам максимально впливати на спільноту людей з інвалідністю. Підтримуючи організацію Edupanel.pl, добродійники стають частиною нашої спільноти, яка прагне подолати бар’єри та створити більш інклюзивний світ для людей з інвалідністю. Ми цінуємо будь-яку форму підтримки, оскільки вона дозволяє нам продовжувати нашу місію та надавати цінну освіту незрячим людям по всьому світу."
            },
            GameFinish:{
                new_game:"Нова гра",
                back:"Назад"
            },
            EduShopAppPlayerWinPage:{
                title:"Вітаємо! Ви виграли."
            },
            EduShopAppPlayerLostPage:{
                title:"На жаль, ви програли"
            },
            EduShopAppPlayerDrawPage:{
                title:"Нічия."
            },
            EduShopAppHowToPlayPage: {
                title: "Правила гри",
                text: "<ol><li>Гра починається на дошці розміром 3x3.</li><li>На початку гри дошка порожня.</li> <li>Гравці ходять по черзі – спочатку гравець, а потім противник AI.</li> <li>Гравець обирає своє поле, вимовивши номер відповідного поля (наприклад, \"Виберіть поле номер 1\", \"Виберіть поле номер 2\" і так далі) або клацнувши на вибране поле на екрані.</li> <li>Після вибору поля, гравець ставить свій знак на дошці. Поле позначається як зайняте.</li> <li>Якщо гравець або AI викладе три свої знаки в один ряд – горизонтальний, вертикальний або діагональний, гра закінчується, і оголошується переможець.</li> <li>Якщо на дошці більше немає доступних полів і ніхто не виклав три знаки в один ряд, гра закінчується внічию.</li> <li>Після закінчення гри, гравець може вирішити, чи хоче він почати нову партію.</li></ol>"
            }
            
        }
    };
}
export default Translate_ua_UA;