import EduDb from './EduDb';
class DbModel {
     tableName = "";
     db = null;
     require=[];
     constructor() {
          this.db = window.App.db;
     }
     async drop(){
          return await this.db.drop(this.tableName);
     }
     async select(where,joins,MAP) {
          let list = [];
          list = await this.db.select(this.tableName, where,joins,MAP);
          return list;
     }
     async selectDB(where) {
          let list = [];
          list = await this.db.selectDB(this.tableName, where);
          return list;
     }
     async getByName(name) {

     }
     async createTable() {
     }
     async load(dd) {
          let this2 = this;
          dd.forEach(async el => {
               await this2.insert(el);
          });
     }
     async insert(val) {
        return  await this.db.insert(this.tableName, val);
     }
}
export default DbModel;